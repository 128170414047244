import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import ares from "./ares";
import authReducer from "./auth";
import notifications from "./notifications";
import channels from "./channels";
import entities from "./entities";
import modal from "./modal";
import statistics from "./statistics";
import { LOGOUT_SUCCESS } from "../actions/auth";
import dataEntering from "./dataEntering/session";
import activity from "./dataEntering/activity";
import annotations from "./annotations";
import * as purchaseInvoiceFormSlice from "../../../features/dataEntering/routes/dataEntering/components/Forms/Invoice/PurchaseInvoice/slice";
import * as salesInvoiceFormSlice from "../../../features/dataEntering/routes/dataEntering/components/Forms/Invoice/SalesInvoice/slice";
import * as pettyCashDisburstmentFormSlice from "../../../features/dataEntering/routes/dataEntering//components/Forms/PettyCash/PettyCashDisburstment/slice";
import * as pettyCashIncomeFormSlice from "../../../features/dataEntering/routes/dataEntering/components/Forms/PettyCash/PettyCashIncome/slice";
import { api as accountingApi } from "../../../services/accounting/api";
import { api as dataEnteringApi } from "../../../services/dataEntering/api";
import { api as managementApi } from "../../../services/management/api";
import { api as supervisingApi } from "../../../services/supervising/api";
import { routerReducer } from "../router/context";

const resourcesReducer = combineReducers({
    annotations,
    statistics,
});

const appReducer = combineReducers({
    router: routerReducer,
    auth: authReducer,
    ares,
    entities,
    channels,
    dataEntering,
    modal,
    notifications,
    activity,
    form,
    resources: resourcesReducer,
    [purchaseInvoiceFormSlice.name]: purchaseInvoiceFormSlice.reducer,
    [salesInvoiceFormSlice.name]: salesInvoiceFormSlice.reducer,
    [pettyCashDisburstmentFormSlice.name]: pettyCashDisburstmentFormSlice.reducer,
    [pettyCashIncomeFormSlice.name]: pettyCashIncomeFormSlice.reducer,
    [accountingApi.reducerPath]: accountingApi.reducer,
    [dataEnteringApi.reducerPath]: dataEnteringApi.reducer,
    [managementApi.reducerPath]: managementApi.reducer,
    [supervisingApi.reducerPath]: supervisingApi.reducer,
});

const rootReducer = (state, action) => {
    let newState = state;

    if (action && action.type === LOGOUT_SUCCESS) {
        const { router } = state;
        newState = { router };
    }

    return appReducer(newState, action);
};

export default rootReducer;
