import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Navbar, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTachometerAlt,
    faSortNumericDown,
    faChartSimple,
    faBriefcase,
} from "@fortawesome/free-solid-svg-icons";
import UserMenu from "../../../../shared/components/Appbar/UserMenu";
import useTranslation from "../../../../shared/hooks/useTranslation";

export default function Appbar() {
    const { t } = useTranslation();

    return (
        <Navbar className="default-appbar" expand="xs" dark tag="header">
            <Nav className="me-auto" navbar>
                <NavItem title={t("nav.manager.account_queue")}>
                    <NavLink
                        to="/supervising/account-queue"
                        className="nav-link"
                        activeClassName="active"
                    >
                        <FontAwesomeIcon icon={faSortNumericDown} />
                        <span className="ms-1 d-none d-md-inline-block">
                            {t("nav.manager.account_queue")}
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem title={t("nav.manager.processing_stats")}>
                    <NavLink
                        to="/supervising/processing-stats"
                        className="nav-link"
                        activeClassName="active"
                    >
                        <FontAwesomeIcon icon={faChartSimple} />
                        <span className="ms-1 d-none d-md-inline-block">
                            {t("nav.manager.processing_stats")}
                        </span>
                    </NavLink>
                </NavItem>
                <NavItem title={t("nav.manager.processing_performance")}>
                    <NavLink
                        to="/supervising/processing-performance"
                        className="nav-link"
                        activeClassName="active"
                    >
                        <FontAwesomeIcon icon={faTachometerAlt} />
                        <span className="ms-1 d-none d-md-inline-block">
                            {t("nav.manager.processing_performance")}
                        </span>
                    </NavLink>
                </NavItem>
            </Nav>
            <Nav navbar>
                <NavItem title={t("attributes.user.role.manager")}>
                    <NavLink to="/management" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faBriefcase} />
                    </NavLink>
                </NavItem>
            </Nav>
            <UserMenu />
        </Navbar>
    );
}
