import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Supervisor as SupervisorRedirect } from "../auth/hocs/withAuthRedirect";
import SupervisorAppbar from "./components/Appbar";
import NotFound from "../../shared/components/NotFound";
import LoadingScreen from "../../shared/components/Loading";

const AccountQueueScreen = React.lazy(() => import("./routes/AccountQueueScreen"));

const ProcessingStatsScreen = React.lazy(() => import("./routes/ProcessingStatsScreen"));

const ProcessingPerformanceScreen = React.lazy(
    () => import("./routes/ProcessingPerformanceScreen"),
);

function Supervisor() {
    return (
        <Suspense fallback={<LoadingScreen />}>
            <SupervisorAppbar />
            <main className="default-layout flex-grow-1 d-flex bg-white">
                <Switch>
                    <Redirect exact from="/supervising" to="/supervising/account-queue" />
                    <Route path="/supervising/account-queue" component={AccountQueueScreen} />
                    <Route path="/supervising/processing-stats" component={ProcessingStatsScreen} />
                    <Route
                        path="/supervising/processing-performance"
                        component={ProcessingPerformanceScreen}
                    />
                    <Route component={NotFound} />
                </Switch>
            </main>
        </Suspense>
    );
}

export default SupervisorRedirect(Supervisor);
