import React from "react";
import { Router } from "react-router-dom";
import Routes from "./features/routing";
import Notifications from "./shared/components/Notifications";
import Modals from "./shared/components/Modals";
import { history } from "./shared/redux/configureStore";
import "./App.scss";

export default function App() {
    return (
        <Router history={history}>
            <div className="container-fluid vh-100 d-flex flex-column bg-primary g-0">
                <Notifications />
                <Routes />
                <Modals />
            </div>
        </Router>
    );
}
