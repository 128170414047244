import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { Supervisor } from "../../../auth/hocs/withAuth";
import { Navbar, Nav, NavItem } from "reactstrap";
import UserMenu from "../../../../shared/components/Appbar/UserMenu";
import useTranslation from "../../../../shared/hooks/useTranslation";

const SupervisorLink = Supervisor(NavLink);

export default function Appbar() {
    const { t } = useTranslation();

    return (
        <Navbar className="default-appbar" expand="xs" dark tag="header">
            <Nav className="me-auto" navbar>
                <NavItem title={t("nav.manager.skipped_documents")}>
                    <NavLink to="/management/units" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faFileAlt} />
                        <span className="ms-1 d-none d-md-inline-block">
                            {t("nav.manager.skipped_documents")}
                        </span>
                    </NavLink>
                </NavItem>
            </Nav>
            <Nav navbar>
                <NavItem title={t("attributes.user.role.supervisor")}>
                    <SupervisorLink to="/supervising" className="nav-link" activeClassName="active">
                        <FontAwesomeIcon icon={faEye} />
                    </SupervisorLink>
                </NavItem>
            </Nav>
            <UserMenu />
        </Navbar>
    );
}
