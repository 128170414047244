import accountSchema from "./account";
import annotationSchema from "./annotation";
import userSchema from "./user";
import uploadedDocumentSchema from "./uploadedDocument";

export const Schemas = {
    ACCOUNT: accountSchema,
    ACCOUNT_ARRAY: { items: [accountSchema] },
    ANNOTATION: annotationSchema,
    ANNOTATION_ARRAY: { items: [annotationSchema] },
    USER: userSchema,
    USER_ARRAY: { items: [userSchema] },
    UPLOADED_DOCUMENT: uploadedDocumentSchema,
    UPLOADED_DOCUMENT_ARRAY: { items: [uploadedDocumentSchema] },
};

export default Schemas;
