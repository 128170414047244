import { api as dataEnteringApi } from "../api";
import { SkipRequest, SkipResponse } from "./types";

export const skipApi = dataEnteringApi.injectEndpoints({
    endpoints: (build) => ({
        skip: build.mutation<SkipResponse, SkipRequest>({
            query: (body) => ({
                url: `api/v1/data_entering/skip`,
                method: "POST",
                body,
            }),
        }),
    }),
});

export const { useSkipMutation } = skipApi;
