import React from "react";
import { Form, Button, FormFeedback, FormGroup, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormToolbar from "../FormToolbar";
import Input from "../FormInputs/InputWrapper";
import contract from "./contract";
import useTranslation from "../../hooks/useTranslation";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/types";
import { notify } from "../../redux/actions/notifications";
import { ArnoldBaseQueryError } from "../../../services/baseQuery";
import ErrorSummary from "../../../features/dataEntering/routes/dataEntering/components/Forms/shared/components/ErrorSummary";

interface AnnotationFormValues {
    content?: string;
}

export default function AnnotationForm({
    onSubmit,
    onSubmitSuccess,
    cancel,
}: {
    onSubmit: (data: AnnotationFormValues) => Promise<void>;
    onSubmitSuccess: () => void;
    cancel: () => void;
}) {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const useFormApi = useForm({ resolver: yupResolver(contract) });
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors, isSubmitting },
    } = useFormApi;

    const submit = handleSubmit(async (data: AnnotationFormValues) => {
        try {
            await onSubmit(data);

            onSubmitSuccess();
        } catch (e) {
            const { data, status, error } = e as ArnoldBaseQueryError;

            if (data) {
                const { errors } = data;

                errors.forEach((err) =>
                    setError("root", {
                        type: err.code,
                        message: err.detail,
                    }),
                );
            } else {
                dispatch(notify({ text: `${status} - ${error}` }));
            }
        }
    });

    return (
        <FormProvider {...useFormApi}>
            <Form onSubmit={submit}>
                <ErrorSummary namespace="annotation" />
                <FormGroup>
                    <Label for="content" className="label-sm">
                        {t("attributes.annotation.content")}
                    </Label>
                    <Input
                        {...register("content")}
                        type="textarea"
                        invalid={!!errors.content}
                        autoFocus
                        rows={5}
                        autoComplete="off"
                    />
                    {errors.content && (
                        <FormFeedback>{t(`errors.messages.${errors.content.type}`)}</FormFeedback>
                    )}
                </FormGroup>
                <FormToolbar>
                    <Button onClick={cancel}>
                        <FontAwesomeIcon icon={faTimesCircle} /> {t("actions.cancel")}
                    </Button>
                    <Button color="primary" id="btnCreate" type="submit" disabled={isSubmitting}>
                        <FontAwesomeIcon icon={faSave} /> {t("actions.continue")}
                    </Button>
                </FormToolbar>
            </Form>
        </FormProvider>
    );
}
