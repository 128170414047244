import React from "react";
import { authenticatedUser } from "../../../../shared/redux/selectors/auth";
import { useSelector } from "react-redux";

const withAuthorization = (allowedRoles) => (WrappedComponent) => (props) => {
    const user = useSelector(authenticatedUser);

    if (!user || !user.role) {
        return null;
    }

    return allowedRoles.includes(user.role) ? <WrappedComponent {...props} /> : null;
};

export const Admin = withAuthorization(["admin"]);
export const Supervisor = withAuthorization(["admin", "supervisor"]);
export const Manager = withAuthorization(["admin", "supervisor", "manager"]);
export const Assistant = withAuthorization(["assistant"]);

export default withAuthorization;
