import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Manager } from "../auth/hocs/withAuthRedirect";
import ManagerAppbar from "./components/Appbar";
import NotFound from "../../shared/components/NotFound";
import LoadingScreen from "../../shared/components/Loading";

const UnitsScreen = React.lazy(() => import("./routes/Units"));

const ManagerScreen = () => (
    <Suspense fallback={<LoadingScreen />}>
        <ManagerAppbar />
        <main className="default-layout flex-grow-1 d-flex bg-white">
            <Switch>
                <Route path="/management/units" component={UnitsScreen} />
                <Redirect exact from="/management" to="/management/units" />
                <Route component={NotFound} />
            </Switch>
        </main>
    </Suspense>
);

export default Manager(ManagerScreen);
