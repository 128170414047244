import PaginateUtils from "../../../utils/paginateUtils";
import { createAction, fetchTypes } from "../utils/actionCreators";
import Accounts from "../../../services/account";

export const ACCOUNTS = fetchTypes("ACCOUNTS");
export const FETCH_ACCOUNT = fetchTypes("ACCOUNT");

export const fetchAccount = {
    request: (accountId) => createAction(FETCH_ACCOUNT.REQUEST, { payload: { accountId } }),
    success: (payload) => createAction(FETCH_ACCOUNT.SUCCESS, { payload }),
    failure: (errors) => createAction(FETCH_ACCOUNT.FAILURE, { errors }),
};

export const fetchAccounts = (filters) => ({
    type: "@@fetch/REQUEST",
    resource: "accounts",
    types: ACCOUNTS,
    key: PaginateUtils.objectToKey(filters),
    fn: (params) => Accounts.list(params, filters),
});
