import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../auth/components/PrivateRoute";
import IndexRedirect from "../auth/components/IndexRedirect";
import DataEntering from "../dataEntering";
import Supervisor from "../supervising";
import Management from "../management";
import Login from "../auth/routes/Login";
import NotFound from "../../shared/components/NotFound";

const Routes = () => (
    <Switch>
        <Route exact path="/" render={() => <IndexRedirect />} />
        <Route exact path="/login" component={Login} />
        <PrivateRoute path="/data-entering" component={DataEntering} />
        <PrivateRoute path="/management" component={Management} />
        <PrivateRoute path="/supervising" component={Supervisor} />
        <Route component={NotFound} />
    </Switch>
);

export default Routes;
