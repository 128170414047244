import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { authenticatedUser } from "../../../../shared/redux/selectors/auth";

const withAuthRedirect = (allowedRoles) => (WrappedComponent) => (props) => {
    const user = useSelector(authenticatedUser);

    if (!user || !user.role) {
        return null;
    }

    return allowedRoles.includes(user.role) ? (
        <WrappedComponent {...props} />
    ) : (
        <Redirect to="/not_found" />
    );
};

export const Admin = withAuthRedirect(["admin"]);
export const Supervisor = withAuthRedirect(["admin", "supervisor"]);
export const Manager = withAuthRedirect(["admin", "supervisor", "manager"]);
export const Assistant = withAuthRedirect(["assistant"]);

export default withAuthRedirect;
