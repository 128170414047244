import { api as dataEnteringApi } from "../api";
import { ProcessResponse } from "./types";

export const processApi = dataEnteringApi.injectEndpoints({
    endpoints: (build) => ({
        process: build.mutation<ProcessResponse, void>({
            query: () => ({
                url: `api/v1/data_entering/process`,
                method: "POST",
            }),
        }),
    }),
});

export const { useProcessMutation } = processApi;
