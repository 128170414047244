import { useDispatch, useSelector } from "react-redux";
import {
    newActivity,
    endActivity,
    startSession,
    updateActivity,
    ActivityStatuses,
} from "../../redux/actions/dataEntering";
import { throttle } from "lodash";
import { useProcessMutation } from "../../../services/dataEntering/process/api";
import { notify } from "../../redux/actions/notifications";

export default () => {
    const dispatch = useDispatch();
    const dataEntering = useSelector((state) => state.dataEntering);
    const activity = useSelector((state) => state.activity);
    const [processMut, { isLoading: isProcessing }] = useProcessMutation();
    const start = () => dispatch(startSession());
    const startActivity = () => dispatch(newActivity());
    const updateActivityStatus = (status) => dispatch(updateActivity({ status }));
    const endAct = () => dispatch(endActivity({ activity, dataEntering }));
    const completeActivity = (entityId, entityType) =>
        dispatch(updateActivity({ entityId, entityType, status: ActivityStatuses.COMPLETED }));

    const process = throttle(async () => {
        if (isProcessing) {
            return;
        }

        try {
            await processMut().unwrap();

            updateActivityStatus(ActivityStatuses.PROCESSED);
        } catch (e) {
            dispatch(notify({ text: `Error processing document - ${JSON.stringify(e)}` }));
        }
    }, 1000);

    const { subscribed, loading, error, empty } = dataEntering;

    const canStartSession = !(loading || subscribed || empty || !!error);

    return {
        processCommand: process,
        isProcessing,
        start,
        startActivity,
        completeActivity,
        updateActivityStatus,
        endActivity: endAct,
        canStartSession,
        ...dataEntering,
        activity,
    };
};
