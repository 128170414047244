import React from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { hideModal } from "../../../../../../shared/redux/actions/modal";
import Form from "../../../../../../shared/components/AnnotationForm";
import useDataEntering from "../../../../../../shared/hooks/dataEntering/useDataEntering";
import { useSkipMutation } from "../../../../../../services/dataEntering/skip/api";
import { SkipRequest } from "../../../../../../services/dataEntering/skip/types";
import { ActivityStatuses } from "../../../../../../shared/redux/actions/dataEntering";

interface SkipModalProps {
    title: string;
}

export default function SkipModal({ title }: SkipModalProps) {
    const dispatch = useDispatch();
    const { updateActivityStatus } = useDataEntering();
    const [skip] = useSkipMutation();
    const hide = () => dispatch(hideModal());

    const onSubmit = async (formData: { content: string }) => {
        const body: SkipRequest = {
            reason: formData.content,
        };

        await skip(body).unwrap();

        updateActivityStatus(ActivityStatuses.SKIPPED);
    };

    return (
        <Modal isOpen fade={false} toggle={hide}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                <Form onSubmit={onSubmit} cancel={hide} onSubmitSuccess={hide} />
            </ModalBody>
        </Modal>
    );
}
