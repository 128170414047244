import { createApi } from "@reduxjs/toolkit/query/react";
import { arnoldBaseQuery } from "../baseQuery";

export const REDUCER_KEY = "managementApi";
export const api = createApi({
    reducerPath: REDUCER_KEY,
    baseQuery: arnoldBaseQuery,
    tagTypes: ["Units"],
    endpoints: () => ({}),
});
