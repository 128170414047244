import React, { useState } from "react";
import { useFormContext, get } from "react-hook-form";
import PropTypes from "prop-types";
import { decamelize } from "humps";
import { Alert, Collapse, ListGroup, ListGroupItem, Badge } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import useTranslation from "../../../../../../../../../shared/hooks/useTranslation";
import "./index.scss";

const ErrorMessage = ({ errors, fieldName, namespace }) => {
    const { translateWithFallback } = useTranslation();
    const error = get(errors, fieldName);

    if (!error) {
        return null;
    }

    if (Array.isArray(error)) {
        return (
            <FieldArrayErrorSummary
                errors={error}
                namespace={namespace}
                fieldArrayName={fieldName}
            />
        );
    }

    const { message, type } = error;

    if (!(message || type)) {
        return null;
    }

    const decamelizedFieldName = decamelize(fieldName);
    const fieldNameTKey = `attributes.${namespace}.${decamelizedFieldName}`;
    const namespacedTypeTKey = `errors.${namespace}.${decamelizedFieldName}.${type}`;
    const generalTypeTKey = `errors.messages.${type}`;

    const tFieldName = translateWithFallback(fieldNameTKey, fieldName);
    const tMessage = translateWithFallback(
        namespacedTypeTKey,
        translateWithFallback(generalTypeTKey, message),
    );

    return (
        <p className="mt-2">
            <span className="fw-bold">{tFieldName}</span> - {tMessage}
        </p>
    );
};

const MessagePropType = PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
});

const FieldArrayErrorPropType = PropTypes.arrayOf(MessagePropType);

ErrorMessage.propTypes = {
    fieldName: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
    errors: PropTypes.objectOf(PropTypes.oneOfType([MessagePropType, FieldArrayErrorPropType]))
        .isRequired,
};

export const FieldArrayErrorSummary = ({ errors, namespace, fieldArrayName }) => {
    const { translateWithFallback } = useTranslation();

    return (
        <ListGroup>
            <p className="fw-bold">
                {translateWithFallback(
                    `attributes.${namespace}.${fieldArrayName}`,
                    `${namespace}/${fieldArrayName}`,
                )}
            </p>
            {errors.map((e, i) => {
                if (!e) {
                    return null;
                }

                const id = uuidv4();

                return (
                    <ListGroupItem
                        className="d-flex justify-content-between align-items-start"
                        key={id}
                        color="danger"
                    >
                        <div className="ms-2 me-auto">
                            {Object.keys(e).map((key) => (
                                <ErrorMessage
                                    key={`${namespace}.${key}-${id}`}
                                    namespace={`${namespace}/${fieldArrayName}`}
                                    errors={e}
                                    fieldName={key}
                                />
                            ))}
                        </div>
                        <Badge color="primary">{i}</Badge>
                    </ListGroupItem>
                );
            })}
        </ListGroup>
    );
};

FieldArrayErrorSummary.propTypes = {
    fieldArrayName: PropTypes.string.isRequired,
    namespace: PropTypes.string.isRequired,
    errors: FieldArrayErrorPropType.isRequired,
};

const ErrorSummary = ({ namespace }) => {
    const {
        formState: { errors },
    } = useFormContext();

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    if (Object.keys(errors).length === 0) {
        return null;
    }

    return (
        <Alert
            className="error-summary"
            color="danger"
            onClick={toggle}
            title={
                isOpen ? t("form_error_summary.title_close") : t("form_error_summary.title_open")
            }
        >
            <h4 className="alert-heading text-center">{t("form_error_summary.heading")}</h4>
            <Collapse isOpen={isOpen}>
                <div className="error-summary-messages">
                    {Object.keys(errors).map((fieldName) => (
                        <ErrorMessage
                            key={fieldName}
                            errors={errors}
                            fieldName={fieldName}
                            namespace={namespace}
                        />
                    ))}
                </div>
            </Collapse>
            <p className="text-center text-muted mt-2 mb-0">
                {isOpen ? t("form_error_summary.title_close") : t("form_error_summary.title_open")}
            </p>
        </Alert>
    );
};

ErrorSummary.propTypes = {
    namespace: PropTypes.string.isRequired,
};

export default ErrorSummary;
